<template>
    <div v-if="ready">
        <div class="form" v-if="activeLogin">
            <h1>小一面試班</h1>
            <h2>學生平台</h2>
            <div class="panel">
                <form>
                <ul class="group">
                    <li><label>用戶名稱</label></li>
                    <li><input type="text" v-model="form.username" required @keyup.enter="login" /></li>
                </ul>
                <ul class="group">
                    <li><label>登入密碼</label></li>
                    <li><input type="password" v-model="form.password" required @keyup.enter="login" /></li>
                </ul>
                <ul class="group error" v-if="error">
                    <li></li>
                    <li>用戶名稱或登入密碼不正確</li>
                </ul>
                <ul class="control">
                    <li><a class="link-forgot-password" @click="forgotPassword">忘記密碼</a></li>
                    <li v-if="!isSubmit"><div class="btn-button" @click="login" >登入</div></li>
                    <li v-if="isSubmit"><div class="loader-image"></div></li>
                </ul>
                </form>
            </div>
        </div>
        <div class="form forgot-password" v-if="activeForgotPassword">
            <h1>忘記密碼</h1>
            <div class="panel" v-if="!foundStudentAccount && !sentRetrieveEmail">
                <ul class="group">
                    <li><label>家長電話號碼</label></li>
                    <li><input type="text" v-model="formFP.phone" required /></li>
                </ul>
                <ul class="group">
                    <li><label>學生出生日期</label></li>
                    <li ref="date-picker" @click="focus"><datePicker v-model="formFP.dob" :weekStartsOn="0" :locale="locale" /></li>
                </ul>
                <div v-if="errorForgotPassword" class="error error-message">
                    請輸入家長電話號碼及學生出生日期
                </div>
                <ul class="control">
                    <li><div class="btn-button dark" @click="backLogin">返回</div></li>
                    <li v-if="!isSubmit"><div class="btn-button" @click="submitForgotPassword">提交</div></li>
                    <li v-if="isSubmit"><div class="loader-image"></div></li>
                </ul>
            </div>
            <div class="panel" v-if="foundStudentAccount && !sentRetrieveEmail">
                <h3 class="get-password">輸入您的電郵地址以獲取新密碼</h3>
                <ul class="group">
                    <li><label>電郵地址</label></li>
                    <li>
                        <input type="text" v-model="formFP.email" required />
                    </li>
                </ul>
                <div v-if="errorRetrievePassword" class="error error-message">
                    請輸有效的電郵地址
                </div>
                <ul class="control">
                    <li><div class="btn-button dark" @click="backLogin">返回</div></li>
                    <li v-if="!isSubmit"><div class="btn-button" @click="retrievePassword">提交</div></li>
                    <li v-if="isSubmit"><div class="loader-image"></div></li>
                </ul>
            </div>
            <div class="panel" v-if="sentRetrieveEmail">
                <div class="success-message control">
                    <div class="message">已傳送新的登入密碼到您的電郵地址。</div>
                    <div class="btn-button dark" @click="backLogin">返回</div>
                </div>
            </div>
        </div>
       
        <div class="copyright">{{ $Lang.get('copyright') }} {{ getYear() }} {{ $Lang.get('copyright-title') }}</div>
    </div>
</template>

<script>
import zh from 'date-fns/locale/zh-TW'
import datePicker from 'vue3-datepicker'
import Fn from './Fn'
export default {
    mixins:[ Fn ],
    components: { datePicker },
    data() {
        return {
            form: { username:'', password:'' },
            formFP: { phone:'', dob:null },
            locale: null,
            activeLogin: true,
            activeForgotPassword: false,
            ready: false,
            error: false,
            isSubmit: false,
            foundStudentAccount: false,
            student_id: null,
            errorForgotPassword: false,
            errorRetrievePassword: false,
            sentRetrieveEmail: false
        }
    },
    mounted() {
        if (!this.Auth.isLogin())
        {
            this.locale = zh
            let token = this.Auth.getLocalToken()
            if (token !== false) {
                const params = new FormData()
                params.append('token', token)
                this.$axios
                    .post(process.env.VUE_APP_API + 'check-login' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                    .then(response => {
                        let r = response.data
                        if (r.success === true)
                        {
                            if (r.token) {
                                this.Auth.cacheLogin(r.token)

                                if (r.questionnaire !== true) {
                                    this.questionnaire()
                                } else {
                                    this.course()
                                }

                                this.$root.showPWA()
                            }
                        }
                        this.ready = true
                    })
            } else {
                this.ready = true
            }
        } else {
            this.questionnaire()
        }
    },
    emits:[ 'swapInner' ],
    methods: {
        questionnaire() {
            this.$emit('swapInner')
            this.$router.push('/questionnaire/')
        },
        course() {
            this.$emit('swapInner')
            this.$router.push('/course/')
        },
        login () {
            this.isSubmit = true
            const params = new FormData()
            for (let i in this.form)
            {
                params.append(i, this.form[i])
            }
            this.$axios
                .post(process.env.VUE_APP_API + 'login' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                .then(response => {
                    let r = response.data
                    if (r.success === true) {
                        if (r.token) {
                            this.Auth.cacheLogin(r.token)

                            if (r.questionnaire !== true) {
                                this.questionnaire()
                            } else {
                                this.course()
                            }
                        }
                    } else {
                        this.error = true
                    }
                    this.isSubmit = false
                })
        },
        forgotPassword () {
            this.activeLogin = false
            this.activeForgotPassword = true
        },
        backLogin () {
            this.formFP = {}
            this.sentRetrieveEmail = false
            this.foundStudentAccount = false
            this.errorForgotPassword = false
            this.errorRetrievePassword = false
            this.student_id = null
            this.activeLogin = true
            this.activeForgotPassword = false
        },
        focus() {
            let o = this.$refs['date-picker']
            var pt = o.offsetParent.offsetTop
            let scrollTop = document.documentElement.scrollTop || window.pageYOfset ||document.body.scrollTop;
            let windowHeight = window.innerHeight

            let d = o.getElementsByClassName('v3dp__popout')
            for(let i in d) {
                if (d[i].style) {
                    d[i].style.top = 'inherit'
                }
            }
            let mq = window.matchMedia('(max-width: 700px)')
            if(!mq.matches) {
                if (pt - scrollTop > windowHeight / 2) {
                    for(let i in d) {
                        if (d[i].style) {
                            let h = d[i].clientHeight
                            d[i].style.top = (h*-1) + 'px'
                        }
                    }
                }
            } else {
                // this.$root.hideLogo()
                for(let i in d) {
                    if (d[i].style) {
                        d[i].style.top = '0px'
                    }
                }
            }
        },
        submitForgotPassword () {
            if (this.formFP.phone !== '' && this.formFP.dob !== null) {
                this.errorForgotPassword = false
                const params = new FormData()
                params.append('phone', this.formFP.phone)
                let date = new Date(this.formFP.dob)
                let dob = date.getFullYear() + '-' + (date.getMonth() +1) + '-' + date.getDate()
                this.isSubmit = true
                params.append('dob', dob)
                params.append('token', this.Auth.getToken())
                this.$axios
                    .post(process.env.VUE_APP_API + 'forgot-password' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                    .then(response => {
                        let r = response.data
                        if (r.success === true) {
                            this.foundStudentAccount = true
                            this.student_id = r.id
                        }
                        this.isSubmit = false
                    })
            } else {
                this.errorForgotPassword = true
            }
        },
        retrievePassword() {
            if (this.formFP.email !== '' && this.validEmailAddress(this.formFP.email) && this.student_id !== null) {
                this.errorRetrievePassword = false
                this.isSubmit = true
                
                const params = new FormData()
                params.append('id', this.student_id)
                params.append('phone', this.formFP.phone)
                let date = new Date(this.formFP.dob)
                let dob = date.getFullYear() + '-' + (date.getMonth() +1) + '-' + date.getDate()
                this.isSubmit = true
                params.append('dob', dob)
                params.append('email', this.formFP.email)

                params.append('token', this.Auth.getToken())

                this.$axios
                    .post(process.env.VUE_APP_API + 'retrieve-password' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                    .then(response => {
                        let r = response.data
                        if (r.success === true)
                        {
                            this.sentRetrieveEmail = true
                        }

                        this.isSubmit = false
                    })

            } else {
                this.errorRetrievePassword = true
            }
        }
    }
}
</script>

<style scoped lang="scss">
.link-forgot-password {
    padding-left:25px;
    box-sizing:border-box;
    vertical-align:middle;
    position:relative;
    &:after {
        background:url('~@/assets/icon-button-link.png') no-repeat center center;
        background-size:contain;
        width:20px;
        height:20px;
        content:'';
        display:block;
        position:absolute;
        top:0px;
        left:0px;
    }
}

.error-message {
    text-align:center;
    padding-bottom:20px;
}
.success-message {
    text-align:center;
    .message { padding-bottom:20px; }
}
.form {
    padding-top:60px;
    width:320px;
    box-sizing:border-box;
    display:block;
    margin:auto;
    h1 { text-align:center; }
    h2 { text-align:center; }

    &.forgot-password {
        .panel {
            .group {
                li {
                    &:first-child { width:110px; }
                }
            }
        }
    }

    .get-password { text-align:center; text-shadow:0px 0px 6px rgba(0,0,0,0.5); }

    .panel {
        padding-top:20px;
        padding-bottom:50px;
        input {
            background:#ffffff;
            border:0px;
            height:30px;
            line-height:30px;
            border-radius:15px;
            box-shadow:2px 2px 0px rgba(32, 100, 125, 0.55);
        }
        .group {
            margin-bottom:10px;
            padding:0px;
            list-style:none;
            display:table;
            width:100%;
            li {
                display:table-cell;
                vertical-align:middle;
                label {
                    text-shadow:1px 1px 1px rgba(0,0,0,0.44);
                }
                input {
                    width:100%;
                    padding:0px 20px;
                    box-sizing:border-box;
                    &:hover {
                        outline:none;
                    }
                    &:focus {
                        outline:none;
                    }
                }
                &:first-child {
                    width:70px;
                }
            }
        }

        .control {
            margin:0px; padding:10px 0px 0px 0px;
            box-sizing:border-box;
            list-style:none;
            display:table;
            width:100%;
            li {
                display:table-cell;
                vertical-align:middle;
                &:last-child {
                    text-align:right;
                }
            }

            .btn-button {
                background:url('~@/assets/btn-light.png') no-repeat center center;
                background-size:container;
                width:98px;
                height:43px;
                line-height:40px;
                letter-spacing:5px;
                text-align:center;
                vertical-align:top;
                display:inline-block;
                color:#ffffff;
                text-shadow:1px 1px 1px rgba(0,0,0,0.44);
                font-size:15px;
                padding:0px;
                padding-left:5px;
                box-sizing:border-box;
                margin:0px;
                cursor:pointer;
                &:hover {
                    filter:brightness(110%);
                }
                &.dark {
                    background-image:url('~@/assets/btn-dark.png');
                }
            }
        }
    }
}
@media only screen and (max-width:700px) {
    .form {
        width:100%;
        max-width:320px;
        padding-left:15px;
        padding-right:15px;

    }
}

.v3dp__datepicker {
    :deep(input) {
        border:0px;
        height:30px;
        width:100%;
        line-height:30px;
        border-radius:15px;
        box-shadow:2px 2px 0px rgba(32, 100, 125, 0.55);
        padding:0px 20px;
        box-sizing:border-box;
        &:focus {
            outline:none;
        }
    }
    --elem-hover-bg-color:var(--base-color-light);
    --elem-selected-bg-color:var(--base-color-light);
}
@media only screen and (max-width:700px) {
    :deep(.v3dp__popout) {
        // position:absolute;
        // top:0px;
        // left:0px;
        // width:100%;
        // height:100vh;
        left:-80px;
        box-sizing:border-box;
        z-index:100000;
    }
}

</style>
